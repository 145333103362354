<template>
    <component :is="isList ? 'li' : 'div'" class="dealer-item">
        <h5>{{ dealer.translated.name }}</h5>
        <p>
            {{ dealer.address }}<br />
            {{ dealer.zipcode }} {{ dealer.city }}
        </p>
        <a v-if="dealer.website && isNL" :href="dealer.website" target="_blank">{{ dealer.website }}</a>
        <a v-if="dealer.email" :href="'mailto:' + dealer.email">{{ dealer.email }}</a>
        <a v-if="dealer.phone" :href="'tel:' + formattedPhone">{{ dealer.phone }}</a>

        <div class="meta" v-if="isNaturapy || isPremium || isProPremiumNL">
            <span
                v-if="isNaturapy"
                class="meta-naturapy logo"
                v-tooltip="$t('dealers.meta_naturapy_label')"
            >
                {{ $t('dealers.meta_naturapy_label') }}
            </span>
            <span
                v-if="isPremiumHobby"
                class="meta-premium"
                :class="{ 'logo': isNL }"
                v-tooltip="isNL ? $t('dealers.meta_premium_label') : null"
            >
                {{ $t('dealers.meta_assortment_label') }}
            </span>
            <span
                v-if="isProPremium"
                class="meta-premium"
                v-tooltip="isNL ? $t('dealers.meta_premium_label') : null"
            >
                {{ $t('dealers.meta_assortment_label') }} 
            </span>
        </div>
    </component>
</template>

<script>
import TooltipDirective from '../../directives/tooltip';

export default {
    props: {
        dealer: Object,
        isList: Boolean,
        segment: String        
    },

    directives: {
        tooltip: TooltipDirective
    },

    computed: {

        isPro(){
            return this.segment?.toLowerCase() == 'pro';
        },

        isNL() {
            return this.dealer.country == 'NL';
        },

        isNaturapy() {
            return (this.dealer.naturapy || this.dealer.naturapypickup);
        },

        isPremium() {
            return (this.dealer.premium || this.dealer.propremium) && !(this.isNL && this.isPro);
        },

        isPremiumHobby() {
            return (this.dealer.premium && !this.isPro);
        },

        isProPremium() {
            return (this.dealer.propremium && this.isPro);
        },

        formattedPhone() {
            return this.dealer.phone?.replace(/[^0-9]/g, '');
        }
    }
};
</script>
