import { Livewire } from '/vendor/livewire/livewire/dist/livewire.esm';

document.addEventListener('DOMContentLoaded', () => {
    // Access the global Livewire object provided by the script.
    if (window.Livewire) {
        setTimeout(function() {
            window.Livewire.start();
            console.log('livewire started');
            handleEventListeners();
        }, 50);
    } else {
        console.error('Livewire not found on window.');
    }

    function handleEventListeners() {
        Livewire.on('validate-inputs', () => {
            const btn = $('.js-btn-next')
            btn.find('.spinner-wrapper.d-none').removeClass('d-none');
            btn.find('.label').css({ opacity: 0 })
        });

        Livewire.on('next-item', () => {
            const btn = $('.js-btn-next')
            btn.find('.spinner-wrapper.d-none').removeClass('d-none');
            btn.find('.label').css({ opacity: 0 })
        });

        Livewire.on('previous-item', () => {
            const btn = $('.js-btn-prev')
            btn.find('.spinner-wrapper.d-none').removeClass('d-none');
            btn.find('.label').css({ opacity: 0 })
        });

        Livewire.on('gotoTop', (id) => {
            var topPosition = 15;

            if(id && id.length) {
                id = id.pop();

                const element = document.getElementById('block-' + id);
                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    topPosition = elementPosition - (window.innerHeight/3);
                }
            }

            window.scrollTo({
                top: topPosition,
                left: 0,
                behaviour: 'smooth'
            });
        })
    }
});
