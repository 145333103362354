<template>
    <div>
        <div
            class="modal fade"
            id="modalCodeInput"
            tabindex="-1"
            aria-labelledby="modalCodeInputLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalCodeInputLabel">
                            {{ $t("checkout.prepaid_box_modal_body_title") }}
                        </h3>
                        <button
                            type="button"
                            class="btn-close"
                            @click="closeModal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <p>{{ $t("checkout.prepaid_box_modal_body_text") }}</p>
                        <input
                            type="text"
                            v-model="voucherCode"
                            v-mask="'NNNN-NNNN-NNNN-NNNN'"
                            placeholder="****-****-****-****"
                            class="form-control text-center"
                        />
                        <div
                            v-if="alertMessage"
                            :class="alertClass"
                            class="alert mb-0 mt-3"
                            role="alert"
                        >
                            {{ alertMessage }}
                        </div>
                        <slot name="notification" v-if="isStockStatusAlert"></slot>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-light"
                            @click="closeModal"
                        >
                            {{ $t("checkout.btn_cancel") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary position-relative"
                            @click="validateVoucher"
                        >
                            <div class="position-absolute top-50 start-50 translate-middle">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    :class="{ 'd-none': !isLoading }"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isLoading }">
                                {{ $t("checkout.btn_prepaid_box_modal_validate") }}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shopware from "../../mixins/shopware";
import { Api } from "../../api/apiClient";
import { EventBus } from "../../api/eventBus.ts";
import _ from "lodash";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            api: new Api(),
            alertMessage: "",
            alertClass: "",
            alertParams: {
                value: null,
                timestamp: Date.now()
            },
            isLoading: false,
            isStockStatusAlert: false,
            modal: null,
            voucherCode: "",
        };
    },
    mixins: [shopware],
    mounted: function () {
        this.modal = new Modal(document.getElementById('modalCodeInput'));

        if (window.location.hash === '#voucher') {
            this.openModal();
        }

        document.querySelectorAll('[href*="#voucher"]').forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                this.openModal();
            });
        });
    },
    watch: {
        'alertParams.timestamp': function() {
            if (this.alertParams.value?.productId) {
                this.isStockStatusAlert = true;

                setTimeout(() => {
                    Livewire.dispatch('product-update', { id: this.alertParams.value.productId });
                }, 100);
            }
        }
    },
    methods: {
        openModal() {
            this.modal.show();
        },
        closeModal() {
            this.modal.hide();
        },
        reload(delay = 2500) {
            // NICE TO HAVE: Make this dynamic that it triggers updates on other components.
            setTimeout(() => {
                window.location = window.location.href.split('#')[0];
            }, delay);
        },
        async validateVoucher() {
            try {
                this.isLoading = true;
                this.isStockStatusAlert = false;
                this.alertParams = {
                    value: null,
                    timestamp: Date.now()
                };

                const unformattedCode = this.voucherCode.replace(/-/g, "");
                await this.api.updateContext();
                const response = await this.api.client.invoke(
                    "addPromotionItem post /checkout/cart/promotion",
                    {
                        code: unformattedCode,
                        languageId: this.api.currentLanguageId,
                    },
                );

                const errors = response.errors;
                if (errors && Object.keys(errors).length !== 0) {
                    const errorMessages = [];
                    let successMessage = null;

                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if (key.startsWith('payment-method-blocked-')) {
                                continue;
                            }

                            const error = errors[key];
                            if (key.startsWith('promotion-discount-added-') || key.startsWith('faulty-voucher-product-combination')) {
                                successMessage = this.getErrorMessage(error);
                            } else {
                                this.alertParams = {
                                    value: error.parameters,
                                    timestamp: Date.now()
                                };
                                errorMessages.push(this.getErrorMessage(error));
                            }
                        }
                    }

                    if (successMessage && errorMessages.length === 0) {
                        this.alertMessage = successMessage;
                        this.alertClass = "alert-success";

                        EventBus.$emit("cart-updated", response);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'add_to_cart',
                            'type': 'voucher',
                            'items': [{
                                'item_id':response.extensions["cart-promotions"].addedCodes[0],
                                'item_name' : 'voucher added'
                            }]
                        });
                        this.reload();
                    } else {
                        this.alertMessage = errorMessages.join(" ");
                        this.alertClass = "alert-warning";

                        if (!errorMessages.length) {
                            this.reload();
                        }
                    }
                } else {
                    this.reload(0);
                }
            } catch (error) {
                this.alertMessage = this.$t("checkout.prepaid_box_modal_msg_failed") . error.message;
                this.alertClass = "alert-warning";
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
